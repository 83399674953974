"use client";

import { updateItemQuantityInDB } from "@/components/features/cart-modal/actions";
import { Button } from "@/components/shared/ui/button";
import { Input } from "@/components/shared/ui/input";
import { Label } from "@/components/shared/ui/label";
import { useCartStore } from "@/state-management/stores/cart-store";
import { Minus, Plus } from "lucide-react";
import { useState, useTransition } from "react";
import { useFormState } from "react-dom";

function SubmitButton({
    type,
    onClick,
    disabled = false
}: {
    type: "plus-case" | "minus-case" | "plus-unit" | "minus-unit";
    // eslint-disable-next-line no-unused-vars
    onClick: (e: React.MouseEvent) => void;
    disabled?: boolean;
}) {
    const ariaLabel = {
        "plus-case": "Increase case quantity",
        "minus-case": "Decrease case quantity",
        "plus-unit": "Increase unit quantity",
        "minus-unit": "Decrease unit quantity"
    }[type];

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
    };

    return (
        <Button
            variant="outline"
            size="icon"
            onClick={handleClick}
            className="h-6 w-6 rounded-sm p-0"
            aria-label={ariaLabel}
            disabled={disabled}
        >
            {type.includes("plus") ? <Plus className="h-3 w-3" /> : <Minus className="h-3 w-3" />}
        </Button>
    );
}

function UnitCaseReducer(input: { units: number; cases: number; wholesaleUnitsPerCase: number }) {
    const totalUnits = input.cases * input.wholesaleUnitsPerCase + input.units;

    return {
        cases: Math.floor(totalUnits / input.wholesaleUnitsPerCase),
        units: totalUnits % input.wholesaleUnitsPerCase
    };
}

export default function CaseUnitInput({
    productID,
    wholesaleUnitsPerCase,
    sellByCaseOnly,
    promotionID,
    initialQuantity
}: {
    productID: number;
    wholesaleUnitsPerCase: number;
    sellByCaseOnly: boolean;
    promotionID: number | null;
    initialQuantity: {
        cases: number;
        units: number;
    };
}) {
    const { updateCartItem } = useCartStore();
    const [message, formAction] = useFormState(updateItemQuantityInDB, null);
    const [isPending, startTransition] = useTransition();
    const [localQuantity, setLocalQuantity] = useState(initialQuantity);

    const totalUnits = localQuantity.cases * wholesaleUnitsPerCase + localQuantity.units;

    const saveNewQuantity = () => async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const units = localQuantity.cases * wholesaleUnitsPerCase + localQuantity.units;

        if (!isNaN(units) && units >= 0) {
            const payload = {
                productID: productID,
                quantity: Math.max(0, units),
                promotionID: promotionID
            };

            startTransition(() => {
                updateCartItem(
                    payload.productID,
                    "set-units",
                    payload.promotionID,
                    payload.quantity
                );
            });

            formAction(payload);

            setLocalQuantity(
                UnitCaseReducer({
                    cases: 0,
                    units: units,
                    wholesaleUnitsPerCase: wholesaleUnitsPerCase
                })
            );
        }
    };

    const handleButtonClick = async (
        type: "plus-case" | "minus-case" | "plus-unit" | "minus-unit"
    ) => {
        const totalUnits = localQuantity.cases * wholesaleUnitsPerCase + localQuantity.units;
        let newQuantity = totalUnits;

        let newCases = Number(localQuantity.cases);
        let newUnits = Number(localQuantity.units);

        switch (type) {
            case "plus-case":
                newQuantity += wholesaleUnitsPerCase;
                newCases += 1;
                break;
            case "minus-case":
                if (newCases > 0) {
                    newQuantity -= wholesaleUnitsPerCase;
                    newCases -= 1;
                }
                break;
            case "plus-unit":
                newQuantity += 1;
                newUnits += 1;
                break;
            case "minus-unit":
                if (newUnits > 0) {
                    newQuantity -= 1;
                    newUnits -= 1;
                } else if (newCases > 0) {
                    newCases -= 1;
                    newUnits = wholesaleUnitsPerCase - 1;
                    newQuantity -= 1;
                }
                break;
        }

        setLocalQuantity(
            UnitCaseReducer({
                cases: newCases,
                units: newUnits,
                wholesaleUnitsPerCase: wholesaleUnitsPerCase
            })
        );

        const payload = {
            productID: productID,
            quantity: Math.max(0, newQuantity),
            promotionID: promotionID
        };

        startTransition(() => {
            updateCartItem(payload.productID, type, payload.promotionID);
        });

        await formAction(payload);
    };

    const setLocalQuantityOnChange =
        (type: "cases" | "units") => (e: React.ChangeEvent<HTMLInputElement>) => {
            setLocalQuantity((prev) => {
                return {
                    ...prev,
                    [type]: Number(e.target.value),
                    wholesaleUnitsPerCase: wholesaleUnitsPerCase
                };
            });

            if (e.target.value === "69") {
                console.log("nice");
            }
        };

    return (
        <div className="w-full max-w-[160px]" onClick={(e) => e.stopPropagation()}>
            <div className="flex flex-col gap-1.5">
                <div className="flex items-center gap-1.5">
                    <Label className="min-w-10 text-sm font-normal text-gray-600">Cases</Label>
                    <div className="flex items-center gap-0.5">
                        <SubmitButton
                            type="minus-case"
                            onClick={() => handleButtonClick("minus-case")}
                            disabled={localQuantity.cases === 0 || isPending}
                        />
                        <Input
                            type="number"
                            value={localQuantity.cases}
                            onBlur={saveNewQuantity()}
                            onChange={setLocalQuantityOnChange("cases")}
                            onFocus={(e) => e.target.select()}
                            className="h-6 w-12 px-1 text-center text-sm [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                            onClick={(e) => e.stopPropagation()}
                            disabled={isPending}
                        />
                        <SubmitButton
                            type="plus-case"
                            onClick={() => handleButtonClick("plus-case")}
                            disabled={isPending}
                        />
                    </div>
                </div>

                {!sellByCaseOnly ? (
                    <div className="flex items-center gap-1.5">
                        <Label className="min-w-10 text-sm font-normal text-gray-600">Units</Label>
                        <div className="flex items-center gap-0.5">
                            <SubmitButton
                                type="minus-unit"
                                onClick={() => handleButtonClick("minus-unit")}
                                disabled={totalUnits === 0 || isPending}
                            />
                            <Input
                                type="number"
                                value={localQuantity.units}
                                onBlur={saveNewQuantity()}
                                onChange={setLocalQuantityOnChange("units")}
                                onFocus={(e) => e.target.select()}
                                className="h-6 w-12 px-1 text-center text-sm [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                                onClick={(e) => e.stopPropagation()}
                                disabled={isPending}
                            />
                            <SubmitButton
                                type="plus-unit"
                                onClick={() => handleButtonClick("plus-unit")}
                                disabled={isPending}
                            />
                        </div>
                    </div>
                ) : null}
            </div>

            <p aria-live="polite" className="sr-only" role="status">
                {message}
            </p>
        </div>
    );
}
